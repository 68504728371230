html, body {
    margin: 0;
}

.App{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.App-container{
    
    width: 1024px;
    height: 768px;
    margin: 0 auto;
}

.orientation-portrait{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}